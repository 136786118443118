import React, { useState, useEffect } from 'react';
import './App.css';

const App = () => {
  const [count, setCount] = useState(null);  // Изначально null для Loading состояния
  const [clicks, setClicks] = useState([]);
  const [loading, setLoading] = useState(true); // Статус загрузки
  const [error, setError] = useState(false);    // Статус ошибки
  const [socket, setSocket] = useState(null);
  const [userData, setUserData] = useState(null);
  const [socketMessage, setSocketMessage] = useState("");
  // Функция для отправки данных на бэк
  const checkTelegramData = async (initData) => {
    try {
      const response = await fetch('https://nihuyatoken.fun/api/user/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ initData: initData }),  // Отправляем initData на бэк
      });

      if (!response.ok) {  // Если ответ не ок, считаем это ошибкой
        throw new Error('Ошибка ответа от сервера');
      }

      const data = await response.json();
      setCount(data.click_balance);  // Устанавливаем баланс кликов от сервера
      setUserData(data);
      setLoading(false);  // Скрываем "Loading"

    } catch (error) {
      console.error('Ошибка валидации:', error);
      setLoading(false)
      setError(true);  // Устанавливаем ошибку
    }
  };

  // Получаем данные initData от Telegram при загрузке страницы
  useEffect(() => {
    const initData = window.Telegram?.WebApp?.initData;  // Проверяем, открыто ли в Телеге

    if (!initData) {
      // Если initData нет (не открыто в Telegram)
      setError(true);
    } else {
      checkTelegramData(initData);  // Отправляем initData на сервер
    }
    

    const ws = new WebSocket('wss://nihuyatoken.fun/wss'); // Укажи свой URL сервера

    ws.onopen = () => {
      setSocket(ws);
      setSocketMessage("Соединение с вебсокетом");
      if (userData) {
        // Отправляем хэш при установлении соединения
        ws.send(JSON.stringify({ action: 'auth', hash: userData.hash, user_id: userData.user_id}));
        setSocketMessage("Соединение с вебсокетом установлено");
      }
      // setSocketMessage("Соединение с вебсокетом установлено");
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      // Здесь можно обрабатывать ответ от сервера
      if (data.message) {
        setSocketMessage(data.message);
      }
    };

    ws.onclose = () => {
      setSocketMessage("Соединение с вебсокетом закрыто");
    };

    ws.onerror = (error) => {
      setSocketMessage("Ошибка вебсокета: " + error.message);
    };

    return () => {
      ws.close(); // Закрытие соединения при размонтировании компонента
    };
  }, []);

  const handleClick = (e) => {
    setCount((prevCount) => prevCount + 1);
    if (socket) {
      // Проверяем состояние соединения перед отправкой сообщения
      if (socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify({ action: 'auth', hash: userData.hash, user_id: userData.user_id }));
        socket.send(JSON.stringify({ action: 'click' }));  // Отправляем факт клика
      } else {
        console.error("WebSocket is not open. State: ", socket.readyState);
        return; // Выходим из функции, если вебсокет не открыт
      }
    }
    const x = e.clientX;
    const y = e.clientY;
    const newClick = { x, y, id: Date.now() };
    setClicks((prevClicks) => [...prevClicks, newClick]);

    setTimeout(() => {
      setClicks((prevClicks) => prevClicks.filter(click => click.id !== newClick.id));
    }, 500);
  };

  if (error) {
    return <div className="App"><h1>Please, reload page</h1></div>;
  }

  if (loading) {
    return <div className="App"><h1>Loading...</h1></div>;
  }

  return (
    <div className="App" onClick={handleClick}>
      <div className="clickDiv">
        <h1>{count}</h1>
      </div>
      {clicks.map((click) => (
        <div
          key={click.id}
          className="plusOne"
          style={{ top: click.y, left: click.x }}
        >
          +1
        </div>
      ))}
    </div>
  );
};

export default App;
